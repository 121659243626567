import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { get, truncate } from 'lodash';
import { Card, Typography } from '@vartanainc/design-system';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { FINANCING_INFORMATION } from '../../graphql/queries/order';
import {
  orderType as ORDER_TYPE,
  orderTypeLabels,
  PLACEHOLDER_STRING,
  SPIFF_MODE,
} from '../../constants/common.constants';
import crossIcon from '../../assets/cross.svg';
import editIcon from '../../assets/edit.svg';
import { formattedTermDuration } from '../../api/utils';
import VartanaLoader from '../../components/VartanaLoader/VartanaLoader';
import {
  titleize,
  removeTrailingZeros,
  reportError,
  isEmptyString,
} from '../../utils/helpers';
import { orderScreenRegex } from './order.constants';
import {
  FinancingInfoInterface,
  FinancingInfoType,
  OrderContractLengthProps,
  OrderCustomerType,
  OrderFormContextType,
  OrderFormValues,
  CustomScheduleFields,
} from './OrderTypes';
import { formatDate } from './order.utils';
import TextInputField from '../../designSystem/TextInput/TextInputField';
import { OrderFormContext } from '../../context/OrderContext';
import ProposalForm from './ProposalForm';
import AutoLoad from '../../components/AutoLoad';
import { useOrderCreation } from '../../utils/hooks/order_creation';
import './orderProposal.scss';
import { getAppraisalStates } from '../../utils/appraisalUtils';
import OrderProposalSummaryV2 from './OrderSummary/OrderProposalSummaryV2';

const PROPOSAL_NAME = 'Proposal';

interface DebounceIdRefProps {
  current: unknown;
}

interface PaymentOption {
  label: string;
  value: string;
  isDisabled: boolean;
}

interface OrderProposalProps {
  id: string;
  index: number;
  showOnRemove: boolean;
  onRemove: () => void;
  selectedCustomer: OrderCustomerType;
  formValues: OrderFormValues;
  setFieldValue: (field: string, value) => void;
  isVartanaFinancingCheckBoxDisabled: boolean;
  productConfig: {
    availablePaymentFrequencies: string[];
    subsidyAllowedOnProduct: boolean;
    showReviewAndSignDocs: boolean;
    availableOrderTypes: string[];
    deferPaymentTerms: number[];
    directPaymentTerms: number[];
    installmentPaymentTerms: number[];
    spiffMode: string;
    defaultSpiffRate: number;
    vendorOrderTypes: string[];
    defaultOrderType: string;
    multipleCountriesEnabled: boolean;
    currency: string;
    currencySymbol: string;
    isSyndicated: boolean;
    isCustomSchedule: boolean;
  };
  customerDetailsLoading: boolean;
  availableTermsData: OrderContractLengthProps[];
  isEditOrder: boolean;
  customScheduleFields: CustomScheduleFields;
}

// TODO - Nuyaan95, MuhammadAhmadEjaz, AamnaAzammm get rid of hard-coded $ here
const DEFAULT_SPIFF_AMOUNT = '$0';
const ZERO_TERM_ERROR = 'Term should be greater than 1.';

function OrderProposalV2({
  id,
  index,
  showOnRemove,
  onRemove,
  selectedCustomer,
  formValues,
  setFieldValue,
  isVartanaFinancingCheckBoxDisabled,
  productConfig,
  customerDetailsLoading,
  availableTermsData,
  isEditOrder,
  customScheduleFields,
}: OrderProposalProps): ReactElement {
  const selectedProposal = `proposals.${index}`;
  const { proposalErrors, setProposalErrors, startDate } = useContext(
    OrderFormContext
  ) as OrderFormContextType;
  const location = useLocation();
  const selectedOrder = get(location, 'state.selectedOrder', {});
  const orderNumber = selectedOrder?.number;
  const customerNumber = get(selectedCustomer, 'number', '');

  const { filterFrequencies, availableTermsLoading } = useOrderCreation();

  const {
    availablePaymentFrequencies = [],
    subsidyAllowedOnProduct,
    availableOrderTypes,
    deferPaymentTerms,
    directPaymentTerms,
    installmentPaymentTerms,
    spiffMode,
    defaultSpiffRate,
    vendorOrderTypes = [],
    defaultOrderType = '',
    multipleCountriesEnabled,
    currency,
    currencySymbol,
    isSyndicated,
    isCustomSchedule,
  } = productConfig;

  const {
    spiffRate,
    amount,
    contractLength,
    endDate,
    paymentFrequency,
    paymentTerm,
    title,
    orderType,
    isDollar,
    subsidy,
    vartanaFinancing,
  } = formValues;

  const paymentOptions = useMemo(() => {
    const options = [] as PaymentOption[];
    const { appraisalApproved, appraisalExpired } = getAppraisalStates(
      get(selectedCustomer, 'creditObject')
    );
    const isApprovedCustomer = appraisalApproved && !appraisalExpired;

    if (availableOrderTypes?.includes(ORDER_TYPE.full_payment)) {
      // add signature payment option if it exists in the product config
      options.push({
        label: orderTypeLabels.direct,
        value: ORDER_TYPE.full_payment,
        isDisabled: false,
      });
    }
    if (
      vendorOrderTypes?.includes(ORDER_TYPE.installments) ||
      (isSyndicated && availableOrderTypes?.includes(ORDER_TYPE.installments))
    ) {
      // add installment plan option if it exists in the product config
      options.push({
        label: orderTypeLabels.installment_plan,
        value: ORDER_TYPE.installments,
        isDisabled: isApprovedCustomer
          ? !availableOrderTypes?.includes(ORDER_TYPE.installments)
          : true,
      });
    }
    if (
      vendorOrderTypes?.includes(ORDER_TYPE.pay_in_full) ||
      (isSyndicated && availableOrderTypes?.includes(ORDER_TYPE.pay_in_full))
    ) {
      // add pay in full option if it exists in the product
      options.push({
        label: orderTypeLabels.full_payment,
        value: ORDER_TYPE.pay_in_full,
        isDisabled: isApprovedCustomer
          ? !availableOrderTypes?.includes(ORDER_TYPE.pay_in_full)
          : true,
      });
    }

    return options;
  }, [selectedCustomer, availableOrderTypes, vendorOrderTypes, isSyndicated]);

  const defaultProductSpiff = defaultSpiffRate * 100;
  const summaryRerenderDebounce = useRef() as DebounceIdRefProps;
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [financeInfoLoading, setFinanceInfoLoading] = useState(false);
  const [financingInformation, setFinancingInformation] = useState<FinancingInfoType[]>(
    []
  );
  const [isTcvOutOfRange] = useState(false);
  const [contractLengthOptions, setContractLengthOptions] = useState<
    OrderContractLengthProps[]
  >([]);
  const [filteredPaymentFrequencies, setFilteredPaymentFrequencies] = useState<string[]>(
    []
  );
  const [creatingChangeRequest, setCreatingChangeRequest] = useState(false);
  const [hideTermDropdown, setHideTermDropdown] = useState(false);

  const [getFinancingInformation] = useLazyQuery(FINANCING_INFORMATION);

  useEffect(() => {
    // auto fill the proposal title if it is empty
    if (!formValues.title && !isEditTitle) {
      setFieldValue(`proposals.${index}.title`, `${PROPOSAL_NAME} ${index + 1}`);
    }
    // added eslint-disable to only execute this useEffect when formValues.title changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.title]);

  // TODO mwahaj92 to set values for side panel
  useEffect(() => {
    setFinancingInformation((prevValues) => {
      const shouldShowTcv = currencySymbol && amount;
      return {
        ...prevValues,
        totalContractValue: shouldShowTcv
          ? `${currencySymbol}${amount}`
          : PLACEHOLDER_STRING,
        availableCredit: get(
          selectedCustomer,
          'formattedBuyerRemainingCredit',
          PLACEHOLDER_STRING
        ),
        paymentTerm:
          contractLength && contractLength !== 0
            ? formattedTermDuration(contractLength)
            : PLACEHOLDER_STRING,
        startDate:
          startDate && contractLength && formatDate(new Date(startDate))
            ? formatDate(new Date(startDate))
            : PLACEHOLDER_STRING,
        endDate:
          endDate && contractLength && formatDate(new Date(endDate))
            ? formatDate(new Date(endDate))
            : PLACEHOLDER_STRING,
        paymentDue: paymentTerm ? `Net-${paymentTerm}` : PLACEHOLDER_STRING,
        paymentFrequencyLabel: contractLength ? paymentFrequency : PLACEHOLDER_STRING,
        spiffAmount: DEFAULT_SPIFF_AMOUNT,
        spiffRate,
      };
    });
  }, [
    defaultSpiffRate,
    amount,
    contractLength,
    endDate,
    paymentFrequency,
    paymentTerm,
    spiffRate,
    startDate,
    selectedCustomer,
    currencySymbol,
    currency,
  ]);

  useEffect(() => {
    // whenever the payment options change, set the default payment option
    if (isEditOrder) return; // do not set default payment option if it is an edit order
    const availablePaymentOptions = paymentOptions.filter((option) => !option.isDisabled);
    const defaultPaymentOption = availablePaymentOptions.find(
      (option) => option.value === defaultOrderType
    );
    const firstPaymentValue = availablePaymentOptions[0]?.value;
    const shouldAutoAssignPayment =
      !formValues.orderType ||
      !availablePaymentOptions.some((option) => option.value === formValues.orderType);
    const canAssignDefaultPayment =
      shouldAutoAssignPayment &&
      defaultPaymentOption &&
      formValues.orderType !== defaultOrderType;
    const canAssignFirstPayment =
      shouldAutoAssignPayment &&
      availablePaymentOptions.length &&
      formValues.orderType !== firstPaymentValue;
    if (canAssignDefaultPayment) {
      setFieldValue(`${selectedProposal}.orderType`, defaultOrderType);
    } else if (canAssignFirstPayment)
      setFieldValue(`${selectedProposal}.orderType`, firstPaymentValue);

    // only execute this useEffect when paymentOptions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentOptions]);

  useEffect(() => {
    setFieldValue(`${selectedProposal}.startDate`, startDate);
  }, [index, setFieldValue, startDate, selectedProposal]);

  useEffect(() => {
    if (availableTermsData.length) {
      setContractLengthOptions(availableTermsData);
    }
  }, [availableTermsData]);

  useEffect(() => {
    if (contractLength) {
      setFilteredPaymentFrequencies(
        filterFrequencies(availablePaymentFrequencies, contractLength, isCustomSchedule)
      );
    }
    // added to avoid too many rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availablePaymentFrequencies, contractLength]);

  /*
   if current selected billing frequency is not found in filteredPaymentFrequencies
   then sets the 0th index value as billing frequency
  */
  useEffect(() => {
    if (filteredPaymentFrequencies.length > 0) {
      if (!filteredPaymentFrequencies.includes(paymentFrequency)) {
        setFieldValue(
          `${selectedProposal}.paymentFrequency`,
          filteredPaymentFrequencies[0]
        );
      }
    }
  }, [
    filteredPaymentFrequencies,
    paymentFrequency,
    index,
    setFieldValue,
    selectedProposal,
  ]);

  const handleSubsidySync = useCallback(
    (financingInfo): void => {
      // Sync the subsidy values from the financing endpoint
      const dollarMaxSubsidy = financingInfo.dollar_max_subsidy?.replace(
        orderScreenRegex.percentOrDollar,
        ''
      );
      const maxSubsidyPercentage = financingInfo.max_subsidy_percentage?.replace(
        orderScreenRegex.percentOrDollar,
        ''
      );

      if (dollarMaxSubsidy || maxSubsidyPercentage) {
        // Set the subsidy values in the form state
        setFieldValue(
          `${selectedProposal}.maxSubsidyAllowed`,
          isDollar
            ? removeTrailingZeros(String(dollarMaxSubsidy))
            : removeTrailingZeros(String(maxSubsidyPercentage))
        );
      }
      setFieldValue(
        `${selectedProposal}.dollarMaxSubsidy`,
        removeTrailingZeros(dollarMaxSubsidy)
      );
      setFieldValue(
        `${selectedProposal}.maxSubsidyPercentage`,
        removeTrailingZeros(maxSubsidyPercentage)
      );
    },
    [isDollar, selectedProposal, setFieldValue]
  );

  const handleFinancingError = useCallback(
    (e) => {
      const errorMessage = get(e, 'message', '');
      if (selectedOrder?.id) {
        reportError(`While editing order #${selectedOrder?.id}: ${errorMessage}`);
      } else {
        reportError(
          `While creating order for customer #${customerNumber}: ${errorMessage}`
        );
      }
    },
    [customerNumber, selectedOrder?.id]
  );

  const isSpiffEmpty = useMemo(() => {
    return isEmptyString(spiffRate) || spiffRate === null || Number.isNaN(spiffRate);
  }, [spiffRate]);

  const generateFinancingInfoPayload = useCallback(() => {
    // TODO- Nuyaan95 replace the conversion here with money library
    const dollarBlindDiscount = isDollar ? parseFloat(subsidy) : 0;
    const percentageBlindDiscount = isDollar ? 0 : parseFloat(subsidy);
    const billingFrequency =
      orderType === ORDER_TYPE.installments ? paymentFrequency : '';
    const term = orderType === ORDER_TYPE.installments ? contractLength || 0 : 0;
    const financingSpiffRate = isSpiffEmpty ? 0 : parseFloat(spiffRate as string);
    return {
      companyNumber: customerNumber,
      term,
      amount: parseFloat(amount as string) || 0,
      paymentTerm,
      billingFrequency: billingFrequency || '',
      percentageBlindDiscount,
      dollarBlindDiscount,
      isDollar,
      useVartanaFinancing: vartanaFinancing,
      selectedPaymentOption: orderType,
      spiffRate: financingSpiffRate,
      orderNumber,
    };
  }, [
    amount,
    contractLength,
    customerNumber,
    isDollar,
    orderType,
    paymentFrequency,
    paymentTerm,
    spiffRate,
    subsidy,
    vartanaFinancing,
    isSpiffEmpty,
    orderNumber,
  ]);

  const getFinancingInfo = useCallback(async (): Promise<void> => {
    try {
      const payload = generateFinancingInfoPayload();

      // call the financing endpoint
      const financingData = await getFinancingInformation({
        variables: payload,
      });
      const financingInfo = get(financingData, 'data.financingInformation', {});
      const serverStartDate = formatDate(new Date(get(financingInfo, 'start_date', 0)));
      const serverEndDate = formatDate(new Date(get(financingInfo, 'end_date', 0)));

      setFieldValue(`${selectedProposal}.startDate`, get(financingInfo, 'start_date'));
      setFieldValue(`${selectedProposal}.endDate`, get(financingInfo, 'end_date'));

      // update the financing information in the state to render the summary section
      setFinancingInformation((prevValues) => {
        return {
          ...prevValues,
          totalContractValue: get(financingInfo, 'amount', 0),
          availableCreditAmount: parseFloat(
            get(financingInfo, 'available_credit.amount', 0)
          ),
          availableCredit: get(
            financingInfo,
            'available_credit.formatted',
            PLACEHOLDER_STRING
          ),
          paymentDue: get(financingInfo, 'payment_term', 0),
          customerApplicationFee: get(financingInfo, 'customer_application_fee', 0),
          vendorApplicationFee: get(financingInfo, 'vendor_application_fee', 0),
          formattedVendorFee: get(financingInfo, 'formatted_vendor_fee', 0),
          netPayout: get(financingInfo, 'net_payout', 0),
          paymentFrequency: get(financingInfo, 'payment', 0),
          paymentTerm: get(financingInfo, 'term', 0),
          totalFees: get(financingInfo, 'total_fees', 0),
          startDate: serverStartDate,
          endDate: serverEndDate,
          spiffAmount: get(financingInfo, 'spiff_amount', DEFAULT_SPIFF_AMOUNT),
        };
      });
      if (Object.values(financingInfo.errors)) {
        setProposalErrors((errors) => {
          const updatedErrors = [...errors];
          updatedErrors[index] = {
            ...financingInfo.errors,
          };
          return updatedErrors;
        });
      }
      handleSubsidySync(financingInfo);
    } catch (e) {
      handleFinancingError(e);
    } finally {
      setFinanceInfoLoading(false);
    }
  }, [
    index,
    setFieldValue,
    getFinancingInformation,
    setProposalErrors,
    selectedProposal,
    handleFinancingError,
    handleSubsidySync,
    generateFinancingInfoPayload,
  ]);

  useEffect(() => {
    // this useEffect is added to call the financing endpoint when the form values are changed

    // this condition is added to avoid unnecessary rerenders and calls to financing endpoint
    const shouldRerenderSummary =
      !availableTermsLoading &&
      amount &&
      orderType &&
      orderType !== ORDER_TYPE.full_payment &&
      customerNumber &&
      paymentTerm !== null &&
      paymentTerm >= 0 &&
      // check that term should not be falsy when installment plan is selected
      !(orderType === ORDER_TYPE.installments && !contractLength);
    if (shouldRerenderSummary) {
      setFinanceInfoLoading(true);
      clearTimeout(summaryRerenderDebounce.current as number);
      const debounceTimeout = setTimeout(() => getFinancingInfo(), 200);
      summaryRerenderDebounce.current = debounceTimeout;
    }
  }, [
    amount,
    isDollar,
    orderType,
    paymentFrequency,
    paymentTerm,
    subsidy,
    vartanaFinancing,
    contractLength,
    index,
    customerNumber,
    selectedOrder?.id,
    setFieldValue,
    getFinancingInformation,
    setProposalErrors,
    availableTermsLoading,
    spiffRate,
    selectedProposal,
    getFinancingInfo,
  ]);

  // removing the amount error proposal errors when amount is cleared
  useEffect(() => {
    if (isEmptyString(amount)) {
      setProposalErrors((errors) => {
        const updatedErrors = [...errors];
        if (updatedErrors[index]?.amount) {
          delete updatedErrors[index].amount;
        }
        return updatedErrors;
      });
    }
  }, [amount, index, setProposalErrors]);

  // adding proposal error when term is entered 0 and order type is installment
  useEffect(() => {
    const isZeroTermError = !contractLength && orderType === ORDER_TYPE.installments;
    if (isZeroTermError) {
      setProposalErrors((errors) => {
        const updatedErrors = [...errors];
        if (updatedErrors[index]) {
          updatedErrors[index].term = ZERO_TERM_ERROR;
        }
        return updatedErrors;
      });
    }
  }, [contractLength, index, orderType, setProposalErrors]);

  useEffect(() => {
    if (orderType === ORDER_TYPE.full_payment) {
      setFieldValue(`${selectedProposal}.paymentTerm`, 0);
      setFieldValue(`${selectedProposal}.vartanaFinancing`, false);
    } else setFieldValue(`${selectedProposal}.vartanaFinancing`, true);
  }, [orderType, index, setFieldValue, selectedProposal]);

  // setting payment term to the first defer payment term if payment term is zero with defer payment
  useEffect(() => {
    if (orderType === ORDER_TYPE.pay_in_full && paymentTerm === 0) {
      setFieldValue(`${selectedProposal}.paymentTerm`, deferPaymentTerms[0]);
    }
  }, [deferPaymentTerms, orderType, paymentTerm, index, setFieldValue, selectedProposal]);

  useEffect(() => {
    const shouldUpdateSpiffRate =
      spiffMode === SPIFF_MODE.fixed && spiffRate !== defaultProductSpiff;
    if (shouldUpdateSpiffRate) {
      setFieldValue(`${selectedProposal}.spiffRate`, defaultProductSpiff);
    }
  }, [
    defaultSpiffRate,
    index,
    setFieldValue,
    spiffMode,
    spiffRate,
    selectedProposal,
    defaultProductSpiff,
  ]);

  const handleTitleFieldBlur = (): void => {
    if (!formValues.title) {
      setFieldValue(`${selectedProposal}.title`, `${PROPOSAL_NAME} ${index + 1}`);
    }
    setIsEditTitle(false);
  };

  const getPricingEngineError =
    proposalErrors?.length > 0 && !!get(proposalErrors, '[index].pricing_engine', '')
      ? get(proposalErrors, '[index].pricing_engine', '')
      : null;

  const showSummarySection = formValues.orderType && formValues.orderType !== 'direct';

  return (
    <Card
      tabindex={0}
      variant="fullWidth"
      containerClassName="relative"
      contentClassName={(customerDetailsLoading && 'min-h-[21.875rem]') || ''}
      content={(
        <AutoLoad
          containerClassName="flex justify-center m-auto"
          loading={customerDetailsLoading}
        >
          <VartanaLoader
            loading={creatingChangeRequest}
            fullscreen
            containerClassName=""
            className=""
          />
          <div>
            {showOnRemove ? (
              <div className="absolute right-0 top-0 p-2">
                <button type="button" onClick={onRemove}>
                  <img alt="close" src={crossIcon} />
                </button>
              </div>
            ) : null}
            <div className="flex divide-x bg-white">
              <div className="flex flex-col w-4/6 gap-y-4 pr-8">
                <div className="flex gap-2 mb-2">
                  {isEditTitle ? (
                    <>
                      <TextInputField
                        {...{
                          name: `${selectedProposal}.title`,
                          placeholder: title,
                          onBlur: handleTitleFieldBlur,
                        }}
                        ref={null}
                      />
                      <span className="flex-1"></span>
                    </>
                  ) : (
                    <>
                      <Typography variant="heading18" bold color="color-black-100">
                        {truncate(title, {
                          length: 50,
                          omission: '...',
                        })}
                      </Typography>
                      <button
                        className="ml-1"
                        type="button"
                        onClick={() => setIsEditTitle(true)}
                      >
                        <img className="w-6 h-6" alt="edit" src={editIcon} />
                      </button>
                    </>
                  )}
                </div>
                <ProposalForm
                  financeInfoLoading={financeInfoLoading}
                  proposalErrors={proposalErrors}
                  formValues={formValues}
                  index={index}
                  deferPaymentTerms={deferPaymentTerms}
                  directPaymentTerms={directPaymentTerms}
                  installmentPaymentTerms={installmentPaymentTerms}
                  selectedCustomer={selectedCustomer}
                  customerNumber={customerNumber}
                  paymentOptions={paymentOptions}
                  contractLengthOptions={contractLengthOptions}
                  filteredPaymentFrequencies={filteredPaymentFrequencies}
                  hideTermDropdown={hideTermDropdown}
                  setHideTermDropdown={setHideTermDropdown}
                  setFieldValue={setFieldValue}
                  spiffMode={spiffMode}
                  subsidyAllowedOnProduct={subsidyAllowedOnProduct}
                  setCreatingChangeRequest={setCreatingChangeRequest}
                  currencySymbol={currencySymbol}
                  isSyndicated={isSyndicated}
                  isCustomSchedule={isCustomSchedule}
                  isDemoVendor={get(productConfig, 'isDemoVendor', false)}
                  customScheduleFields={customScheduleFields}
                />
              </div>
              {showSummarySection && (
                <div className="w-2/6 pl-8">
                  <AutoLoad
                    loading={financeInfoLoading}
                    containerClassName="h-full flex items-center"
                  />
                  <div className={financeInfoLoading ? 'invisible' : ''}>
                    <OrderProposalSummaryV2
                      // TODO: Nuyaan95 to fix this type casting issue
                      financingInformation={
                        financingInformation as unknown as FinancingInfoInterface
                      }
                      currentOrderType={orderType}
                      vartanaFinancingSelected={vartanaFinancing}
                      billingMethods={get(
                        selectedCustomer,
                        'availablePaymentMethods',
                        []
                      )}
                      companyName={titleize(get(selectedCustomer, 'name', ''))}
                      companyNumber={customerNumber}
                      isPreFinanced
                      invoiceEnabled={get(selectedCustomer, 'invoiceEnabled', false)}
                      tcvOutOfRange={isTcvOutOfRange}
                      pricingEngineError={getPricingEngineError}
                      multipleCountriesEnabled={multipleCountriesEnabled}
                      currency={currency}
                      isCustomSchedule={isCustomSchedule}
                      customScheduleFields={customScheduleFields}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </AutoLoad>
      )}
    />
  );
}

export default OrderProposalV2;
